// css
@import '../variables';

h1 {
    font-size: 28px;
}

/**
  kup.common.ui overrides
*/
.data-table-table,
table.table-table {
    max-height: 10000px !important;
    height: auto !important;
}

.inline-datepicker {
    display: inline-block;
    margin-right: 20px;
    max-width: 150px;
}

formly-field-mat-radio {
    .mat-radio-group {
        display: inline-flex;
        flex-direction: column;
    }

    .mat-radio-button {
        margin: 5px;
    }
}
/**
  Prevent mat-stepper from clipping select dropdowns
*/
.mat-horizontal-content-container {
    overflow: unset !important;
}

.inline-external-reference {
    display: inline-block;
    margin-right: 20px;
    width: 33%;
}

.align-with-type {
    vertical-align: bottom;
}

.mat-drawer-container {
    z-index: auto !important;
}

// Style the SweetAlert2 dialog component
.swal2-container {
    font-family: Roboto, 'Helvetica Neue', sans-serif !important;
    .swal2-custom {
        &.swal2-popup .swal2-header {
            flex-direction: row;

            .swal2-title {
                font-size: 1.4em;
                text-align: left;
            }

            .swal2-icon {
                width: 2em;
                height: 2em;
                line-height: 2em;
                margin: 0.25em 15px 0.9em 0px;
                .swal2-icon-text {
                    font-size: 2em;
                }
            }

            .swal2-icon.swal2-error {
                .swal2-x-mark {
                    .swal2-x-mark-line-left {
                        top: 0.82em;
                        left: 0.3em;
                        width: 1.4em;
                    }
                    .swal2-x-mark-line-right {
                        top: 0.82em;
                        left: 0.3em;
                        width: 1.4em;
                    }
                }
            }

            .swal2-icon.swal2-success {
                .swal2-success-circular-line-left {
                    display: none;
                }

                .swal2-success-circular-line-right {
                    display: none;
                }

                .swal2-success-fix {
                    display: none;
                }

                .swal2-success-line-tip {
                    top: 1em;
                    left: 0.17em;
                    width: 0.75em;
                }

                .swal2-success-line-long {
                    top: 0.88em;
                    right: 0.1em;
                    width: 1.4em;
                }
            }
        }
    }
}

// Make the color of the modal cancel button consistent with the theme
.mat-flat-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
    background-color: $color-validation-error;
}

.create-biomaterial-btn {
    margin-left: auto !important;
}
#CreateBiomaterialsDialog {
    overflow: visible;

    * {
        font-family: Roboto, 'Helvetica Neue', sans-serif !important;
    }
}

.change-status-txt {
    font-size: 14px !important;
    font-family: Roboto, 'Helvetica Neue', sans-serif !important;
}

button[disabled] mat-icon svg {
    opacity: 0.5;
}
