/*!
 * Global Styles Entry Point
 *
 * Main entry point for the styles used in our app.
 * Framework and components specific to this project
 * should be imported here. To change framework styles,
 * do so in the referenced common.ui project.
 *
 * Note: Styles defined here are not namespaced.
 * Therefore, they are shared across the entire app.
 * Specific component styles are set in the angular
 * components themselves.
 */
// Framework
@import 'node_modules/kup.common.ui/src/styles/framework';

// Components
@import './components/components.spinner';
@import './components/components.ng-select';
@import './components/componets.mat-stepper';

// Objects
@import 'objects/objects.list-bare';
@import 'objects/objects.list-inline';

// Utilities
@import './variables';

// Global styles
@import 'global/global.styles';

// Ensure that placeholder/label does not overflow on ng-select multi-select types
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
    margin-top: 4px;
}

.selector-menu-panel {
    .mat-checkbox-checked.mat-accent .mat-checkbox-background {
        background-color: $color-app-primary;
    }
}
// Make the ng-select focused placeholder color consistent with other form controls
.ng-select.ng-select-focused .ng-select-container .ng-value-container .ng-placeholder {
    color: $color-focused-label;
}

// Style the selcted items in a ng-select dropdown to be consistent with the theme
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    color: $color-app-primary-font;
    background-color: $color-app-primary;
    border-bottom: solid 1px $color-subtle-control-border;
}
.mat-tab-label-active {
    opacity: 1 !important;
}
