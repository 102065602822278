.o-list-inline {
    display: inline;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
    margin: 0;
}

.o-list-inline__item {
    display: inline;
    align-items: center;
}

.o-list-inline__item + .o-list-inline__item {
    margin-left: 8px;
}

.o-list-inline__item .o-list-inline__item button {
    margin-bottom: 8px;
}
